@import "@/assets/scss/style";

/* ===================================
    Team
====================================== */

.team-area {
    & .about-img{
        position: relative;
        text-align: center;

        & img{
            width: 100%;

            @media #{$md}{
                width: initial;
            }

            @media #{$xs}{
                height: auto;
            }
        }
        & .about-overlay-box{
            position: absolute;
            top: 50%;
            left: 50%;
            @include transform(translate(-50%,-50%));
            width: 140px;
            height: 140px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            background-color: $theme-color;
            border: 5px solid #fff;
            border-radius: 50%;
            cursor: pointer;
            & h4{
                color: $white;
                line-height: 1.4;
                & span{
                    font-size: 16px;
                    display: contents;
                }
            }
        }
    }

    & .about-content-box{
        & p{
            margin-bottom: 18px;
        }
        & .info-box{
            & .icon{
                & i{
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    border-radius: 50%;
                    box-shadow: 0px 8px 20px 0px rgba(159, 165, 173, 0.35);
                    background-color: $white;
                    color: $theme-color;
                }
            }
            & .info{
                margin-left: 15px;
                & h6{
                    font-weight: 500;
                }
            }
        }
        & ul.button{
            & li{
                display: inline-block;
                @media #{$xs}{
                   margin-bottom: 20px;
                }
                &:last-child{
                    margin-left: 40px;
                    @media #{$xss}{
                        margin-left: 5px;
                    }
                }
                & .phone{
                    color: $theme-color;
                    vertical-align: middle;
                    & i{
                        margin-right: 15px;
                        font-size: 28px;
                    }
                }
            }
        }
    }
}


#team-slider{
    text-align: center;
}

.team-box{
    text-align: center;
    margin: 0 10px;
    display: inline-block;
    max-width: 380px;
    border: 20px solid #ffffff;
    background-color:#ffffff;
}

.team-box .team-image{
    position: relative;
    overflow: hidden;
}

.team-box .team-text{
    text-align: center;
    font-size: 14px;
    padding: 2rem 0 2.5rem 0;
}

.team-box .team-text h5{
    font-size: 1.6rem;
}
.team-progress{
    padding-bottom: 1rem;
}

.team-progress .progress-item:not(:last-child){
    margin-bottom: 2rem;
}

.team-progress .progress{
    height: 12px;
    margin-top: 12px;
    overflow: hidden;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    background-color: #eaeaea;
}

.team-progress .progress-item .count{
    font-weight: 500;
}

.team-progress .progress-bar{
    height: 100%;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    background: $theme-color;
    // background: -moz-linear-gradient(left, #3264f5 2%, #7202bb 82%);
    // background: -webkit-linear-gradient(left, #3264f5 2%,#7202bb 82%);
    // background: linear-gradient(to right, #3264f5 2%,#7202bb 82%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3264f5', endColorstr='#7202bb',GradientType=1 );
}

.team-box:hover .overlay {
    opacity: 1;
    visibility: visible;
}
.team-box .overlay {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.center-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.overlay {
    position: absolute;
    left: 20px;
    top: 25px;
    right: 20px;
    bottom: 22px;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    -webkit-transition: all .5s ease-in .1s;
    -o-transition: all .5s ease-in .1s;
    transition: all .5s ease-in .1s;
}

.team-box .overlay:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #3264f5;
    background: -moz-linear-gradient(left, #3264f5 2%, #7202bb 82%);
    background: -webkit-linear-gradient(left, #3264f5 2%,#7202bb 82%);
    background: linear-gradient(to right, #3264f5 2%,#7202bb 82%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3264f5', endColorstr='#7202bb',GradientType=1 );
    opacity: .5;
}

.team-box .team-social li {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
}

.team-box:hover .team-social li {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}

.team-box .team-social li {
    display: inline-block;
    -webkit-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
}
.team-box .team-social li:first-child {
    -webkit-transition-delay: .1s;
    -o-transition-delay: .1s;
    transition-delay: .1s;
}
.team-box .team-social li:nth-child(2) {
    -webkit-transition-delay: .2s;
    -o-transition-delay: .2s;
    transition-delay: .2s;
}
.team-box .team-social li:nth-child(3) {
    -webkit-transition-delay: .3s;
    -o-transition-delay: .3s;
    transition-delay: .3s;
}
.team-box .team-social li:nth-child(4) {
    -webkit-transition-delay: .4s;
    -o-transition-delay: .4s;
    transition-delay: .4s;
}
.team-box .team-social li:nth-child(5) {
    -webkit-transition-delay: .5s;
    -o-transition-delay: .5s;
    transition-delay: .5s;
}
.team-box .team-social li:nth-child(6) {
    -webkit-transition-delay: .6s;
    -o-transition-delay: .6s;
    transition-delay: .6s;
}
.team-box .team-social li:nth-child(7) {
    -webkit-transition-delay: .7s;
    -o-transition-delay: .7s;
    transition-delay: .7s;
}

.team-box .team-social li a {
    display: block;
    font-size: 16px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border: 1px solid;
    width: 36px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: relative;
    z-index: 1;
    overflow: hidden;
    background: #fff;
    border-color: #FFFFFF;
    color: #384141;
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, .1);
    box-shadow: 0 0 3px rgba(0, 0, 0, .1);
    -webkit-transition: all .1s ease;
    -o-transition: all .1s ease;
    transition: all .1s ease;
}


/* ===================================
   Team Two
====================================== */

.team-box-two{
    max-width: 600px;
    margin: 0 auto;
}

.team-box-two .team-two-block{
    background:#fbfbfb;
    overflow: hidden;
    width: 100%;
    position: relative;
    margin-bottom: -1px;
}

.team-two-block .team-image{
    width: 100%;
    overflow: hidden;
}

.team-two-block .team-image img{
    transform:scale(1.0);
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.team-box-two .team-two-block:hover .team-image img{
    transform:scale(1.1);
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.team-box-two .team-two-block li{
    width: 50%;
    display: inline-block;
    position: relative;
}

.team-box-two .team-two-content{
    padding:0 25px;
}
.team-box-two .team-two-content span{
    font-weight: 500;
    margin-bottom: .4rem;
    display: inline-block;
}

.team-box-two .team-two-social{
    margin-top: 1rem;
}

.team-box-two .team-two-social a {
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 50px;
    font-size: 17px;
    margin-right: 1px;
    color: #9a9a9a;
    border: 1px solid transparent;
    -webkit-transition: background-color .2s ease-in-out;
    -moz-transition: background-color .2s ease-in-out;
    -ms-transition: background-color .2s ease-in-out;
    -o-transition: background-color .2s ease-in-out;
    transition: background-color .2s ease-in-out;
}

.team-box-two .team-two-social a:hover{
    background-color:#ffffff;
    border: 1px solid #ffffff;
}

.team-box-two .team-two-block::before {
    content: "";
    position: absolute;
    top: 0;
    right: auto;
    left: auto;
    width: 50%;
    height: 100%;
    bottom: 0;
    background-size: 200% auto;
    background-image: linear-gradient(to right, #3264f5 0%, #7202bb 51%, #3264f5 100%);
    opacity: 0;
    visibility: hidden;
}
.team-box-two .team-two-block.left::before{
    left: -50%;
}

.team-box-two .team-two-block.left:hover::before{
    left: 50%;
    opacity: 1;
    visibility: visible;
}
.team-box-two .team-two-block.right::before{
    right: -50%;
}

.team-box-two .team-two-block.right:hover::before{
    right: 50%;
    opacity: 1;
    visibility: visible;
}
.team-box-two .team-two-block:hover .team-two-content, .team-box-two .team-two-block:hover .team-two-content p{
    color:#ffffff;
}
.team-box-two .team-two-block:hover .team-two-content span  {
    -webkit-text-fill-color: #ffffff;
    background: transparent;
}

.team-box-two .team-two-block:hover .team-two-social a{
    color: #ffffff;
}


/*  Team Box Three  */

#team-three-slider{
    margin-top: 2rem;
}

.team-box-three .team-image{
    max-width: 200px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 2rem;
    transform: translateX(1px);
}

.team-box-three .team-image img{
    transform: scale(1);
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.team-box-three:hover .team-image img
{
    transform: scale(1.1);
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.team-box-three .team-text h6{
    font-size: 1.2rem;
    font-weight: 300;
}

.team-box-three .team-three-social a {
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 50px;
    font-size: 17px;
    margin-right: 1px;
    margin-top: 10px;
    color: #3b3b3b;
    border: 1px solid transparent;
    -webkit-transition: background-color .2s ease-in-out;
    -moz-transition: background-color .2s ease-in-out;
    -ms-transition: background-color .2s ease-in-out;
    -o-transition: background-color .2s ease-in-out;
    transition: background-color .2s ease-in-out;
}
