@import "@/assets/scss/style";
.service-v1{
    background-size: auto;
    background-position: top right;
    background-repeat: no-repeat;

    & .service-item {
        position: relative;
        padding: 75px 45px 30px;
        background-color: $white;
        box-shadow: 0px 12px 30px 0px rgba(174, 184, 198, 0.2);
        @media #{$lg}{
            padding: 75px 30px 30px;
        }
        @media #{$xm}{
            padding: 75px 30px 30px;
        }
        &:hover{
            & .service-icon{
                background-color: $heading-color;
            }
        }
        & .service-icon{
            position: absolute;
            width: 80px;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: $theme-color;
            border: 5px solid $white;
            box-shadow: 0px 8px 25px 0px rgba(85, 91, 99, 0.25);
            top: -40px;
            left: 50%;
            @include transform(translateX(-50%));
            @include transition(.3s);
            & i{
                line-height: 1;
                font-size: 30px;
                color: $white;
            }
        }
        & .service-content{
            & h3.title{
                font-size: 22px;
                line-height: 32px;
                margin-bottom: 20px;
                @include transition(.2s);
                &:hover,&:focus{
                    color: $theme-color;
                }
            }
            & p{
                margin-bottom: 17px;
            }
            & .btn-link{
                position: relative;
                color: $heading-color;
                font-weight: 500;
                &:after{
                    position: absolute;
                    content: '';
                    bottom: 0;
                    left: 0;
                    width: 0%;
                    height: 2px;
                    background-color: transparent;
                    @include transition(.2s);
                }
                &:hover,&:focus{
                    text-decoration: none;
                    color: $theme-color;
                    &:after{
                        background-color: $theme-color;
                        width: 100%;
                    }
                }
            }
        }
    }
}

.service-details-area{
    & .sidebar-widget-area{
        margin-right: 20px;
        & .widget{
            &.service-nav-widget{
                padding: 35px 40px 35px;
                @media #{$lg}{
                    padding: 35px 15px 40px;
                }
                @media #{$xm}{
                    padding: 35px 20px 40px;
                }
                & ul.service-nav{
                    & li{
                        padding-bottom: 10px;
                        border-bottom: 1px solid #eaeaea;
                        margin-bottom: 20px;
                        &:last-child{
                            margin-bottom: 0px;
                            border-bottom: none;
                            padding-bottom: 0;
                        }
                        & i{
                            width: 25px;
                            height: 25px;
                            line-height: 1;
                            color: $theme-color;
                            font-size: 25px;
                            margin-right: 20px;
                        }
                        & a{
                            &:hover,&:focus{
                                color: $theme-color;
                            }
                        }
                    }
                }
            }
            &.newsletter-widget{
                padding: 0;
                & .newsletter-box{
                    position: relative;
                    z-index: 1;
                    text-align: center;
                    padding: 70px 40px;
                    @media #{$lg}{
                        padding: 70px 20px;
                    }
                    @media #{$xm}{
                        padding: 70px 20px;
                    }
                    &:after{
                        position: absolute;
                        top: 0;
                        left: 0;
                        content: '';
                        width: 100%;
                        height: 100%;
                        background-color: rgba(14, 27, 44, 0.85);
                        z-index: -1;
                    }
                    & h3{
                        margin-bottom: 5px;
                    }
                    & h3,& p{
                        color: $white;
                    }
                    & p{
                        margin-bottom: 30px;
                    }
                    & form{
                        & .form_group{
                            & .form_control{
                                height: 55px;
                                border-radius: 28px;
                                margin-bottom: 15px;
                            }
                        }
                    }
                }
            }
            &.download-widget{
                @media #{$lg}{
                    padding: 35px 20px 40px;
                }
                @media #{$xm}{
                    padding: 35px 20px 40px;
                }
                & .download-item{
                    position: relative;
                    padding-bottom: 25px;
                    border-bottom: 1px solid #eaeaea;
                    margin-bottom: 25px;
                    &:last-child{
                        margin-bottom: 0px;
                        padding-bottom: 0px;
                        border-bottom: none;
                    }
                    & h5{
                        font-weight: 600;
                        margin-bottom: 5px;
                    }
                    & .pdf{
                        color: $theme-color;
                    }
                    & .icon{
                        position: absolute;
                        top: 0;
                        right: 0;
                        line-height: 50px;
                        width: 50px;
                        height: 50px;
                        text-align: center;
                        border-radius: 50%;
                        line-height: 50px;
                        font-size: 18px;
                        background-color: $theme-color;
                        color: $white;
                    }
                }
            }
        }
    }
    & .service-details-wrapper{
        & .service-content{
            margin-top: 30px;
            & span.span{
                color: $theme-color;
            }
            & h3{
                margin-bottom: 33px;
            }
            & p{
                margin-bottom: 25px;
            }
            & .features-wrapper{
                margin-bottom: 10px;
                & .icon-box{
                    display: flex;
                    & .icon{
                        & i{
                            font-size: 50px;
                            width: 50px;
                            height: 50px;
                            line-height: 1;
                            color: $theme-color;
                        }
                    }
                    & .info{
                        margin-left: 15px;
                    }
                }
            }
            & .content-block{
                margin-bottom: 33px;
                & .block-text{
                    & h3{
                        margin-bottom: 25px;
                    }
                    & ul.list{
                        & li{
                            margin-bottom: 5px;
                            &:last-child{
                                margin-bottom: 0px;
                            }
                            &:before{
                                font-family: 'Font Awesome 5 Free';
                                font-weight: 600;
                                content: '\f058';
                                color: $theme-color;
                                padding-right: 15px;
                            }
                        }
                    }
                }
            }
            & .video-box{
                position: relative;
                width: 100%;
                height: 400px;
                z-index: 1;
                margin-bottom: 34px;
                &:after{
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    content: '';
                    background-color: rgba(14, 27, 44, 0.75);
                    z-index: -1;
                }
            }
            & .play-content{
                position: absolute;
                top: 50%;
                left: 50%;
                @include transform(translate(-50%,-50%));
                & .video-popup{
                    position: relative;
                    width: 70px;
                    height: 70px;
                    text-align: center;
                    line-height: 70px;
                    border-radius: 50%;
                    color: $white;
                    background-color: $theme-color;
                    z-index: 1;
                    &:after{
                        position: absolute;
                        content: '';
                        top: 50%;
                        left: 50%;
                        @include transform(translate(-50%,-50%));
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        border: 1px solid $theme-color;
                        animation: play 1500ms ease-out infinite;
                        z-index: -1;
                    }
                }
            }
        }
    }
}
