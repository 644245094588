@import "@/assets/scss/style";
.about-area {
    & .about-img{
        position: relative;
        text-align: center;

        & img{
            width: 100%;

            @media #{$md}{
                width: initial;
            }

            @media #{$xs}{
                height: auto;
            }
        }
        & .about-overlay-box{
            position: absolute;
            top: 50%;
            left: 50%;
            @include transform(translate(-50%,-50%));
            width: 140px;
            height: 140px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            background-color: $theme-color;
            border: 5px solid #fff;
            border-radius: 50%;
            & h4{
                color: $white;
                line-height: 1.4;
                & span{
                    font-size: 16px;
                    display: contents;
                }
            }
        }
    }

    & .about-content-box{
        & p{
            margin-bottom: 18px;
        }
        & .info-box{
            & .icon{
                & i{
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    border-radius: 50%;
                    box-shadow: 0px 8px 20px 0px rgba(159, 165, 173, 0.35);
                    background-color: $white;
                    color: $theme-color;
                }
            }
            & .info{
                margin-left: 15px;
                & h6{
                    font-weight: 500;
                }
            }
        }
        & ul.button{
            & li{
                display: inline-block;
                @media #{$xs}{
                   margin-bottom: 20px;
                }
                &:last-child{
                    margin-left: 40px;
                    @media #{$xss}{
                        margin-left: 5px;
                    }
                }
                & .phone{
                    color: $theme-color;
                    vertical-align: middle;
                    & i{
                        margin-right: 15px;
                        font-size: 28px;
                    }
                }
            }
        }
    }
}
