@import "@/assets/scss/style";
.footer {
	position: relative;

	p {
		color: $white;
		font-size: 15px;

		& a {
			font-size: 13px;
		}
	}

	& a {
		font-size: 15px;
		color: $white;
		&:hover,
		&:focus {
            text-decoration: none;
			color: $theme-color;
		}
	}

	& .center-logo {
		display: flex;
		align-items: center;
		height: 100%;
	}

	.footer-logo {
		@media #{$xm} {
			display: none;
		}
	}

	.svh-logo {
		margin-top: -6px;
		height: 83px;
	}

	.footer-text {
		@media #{$xs} {
			margin-bottom: 25px;
		}
	}

	& .usefull-links-list {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-left: -4px;
		margin-right: -4px;
		height: 100%;

		@media #{$xs} {
			justify-content: space-between;
		}

		& li {
			padding-left: 4px;
			padding-right: 4px;
			& a {
				position: relative;
				display: block;

				&:hover {
					& .insta-overlay {
						visibility: visible;
						opacity: 1;
					}
				}
				& .insta-overlay {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: $theme-color;
					display: flex;
					align-items: center;
					justify-content: center;
					visibility: hidden;
					opacity: 0;
					@include transition(0.3s);
					& i {
						color: $white;
					}
				}
			}
		}
	}

	& .copyright-area {
		background-color: #0e1b2c;
		padding: 6px 0;
		& .footer-social {
			@media #{$xs} {
				text-align: center;
			}
			& ul.social-link {
				& li {
					margin-right: 25px;
				}
			}
		}
		& .copyright-text {
			float: right;
			@media #{$xs} {
				float: none;
				text-align: center;
				margin-top: 10px;
			}
			& p {
				font-size: 13px;
				color: $white;
			}
		}
	}
	&.footer-v2 {
		z-index: 1;
		&::after {
			position: absolute;
			content: '';
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(14, 27, 44, 0.9);
			z-index: -1;
		}
		& .newsletter-area {
			border-color: rgba(229, 229, 229, 0.15);
			& .newsletter-form {
				& .form_group {
					& .form_control {
						background-color: rgba(255, 255, 255, 0.15);
						box-shadow: none;
						color: $white;
						&::placeholder {
							color: $white;
						}
					}
					& .main-btn {
					}
				}
			}
		}
		& .footer-widget {
			& .widget {
				& h4.widget-title {
					color: $white;
				}
				&.contact-widget {
					& .contact-info {
						& .single-info {
							& .icon {
								background-color: $theme-color;
								box-shadow: none;
								color: $white;
							}
							& .info {
								& p {
									color: $white;
								}
							}
						}
					}
				}
				&.useful-link-widget {
					& ul.widget-link {
						& li {
							& a {
								color: $white;
								&:hover,
								&:focus {
									color: $theme-color;
								}
							}
						}
					}
				}
				&.recent-news-widget {
					& .blog-item {
						& .entry-content {
							& h3.title {
								color: $white;
							}
						}
					}
				}
			}
		}
	}
}
ul.social-link {
	& li {
		display: inline-block;
	}
}
