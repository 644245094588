@import "@/assets/scss/style";
.transparent-header{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
}
/* Header Navigation*/
.header-navigation{
	position: relative;
	@media #{$lg}{
		padding: 15px 0;
	}
	@media #{$xm}{
		padding: 15px 0;
	}

	& .menu-item--mobile-only {
		@media #{$lg} {
			display: none !important;
		}
		@media #{$xl} {
			display: none !important;
		}
	}
	& .main-menu{
		& ul{
			& > li{
				&.menu-item-has-children{
					& > a{
						&:after{
							content: "\f067";
							font-family: 'Font Awesome 5 Free';
							font-weight: 600;
							margin-left: 5px;
							display: inline-block;
							color: $heading-color;
							font-size: 14px;
							@media #{$lg}{
								display: none;
							}
							@media #{$xm}{
								display: none;
							}
						}
					}
				}
			}
		}
		& ul li {
			display: inline-block;
			position: relative;
			& > a {
				display: block;
				font-size: 16px;
				color: $heading-color;
				font-weight: 500;
				font-family: $roboto;
				padding: 38px 15px;
				line-height: 1;
			}
			& .sub-menu {
				position: absolute;
				left: 0;
				top: 120%;
				width: 250px;
				background-color: #fff;
				opacity: 0;
				visibility: hidden;
				@include transition (0.2s);
				z-index: 99;
				height: auto;
				& li {
					display: block;
					margin: 0;
					&:last-child{
						& > a{
							border-bottom: none;
						}
					}
					& a {
						display: block;
						padding: 8px 15px;
						font-size: 14px;
						position: relative;
						transition: all 0.3s ease-out 0s;
						border-radius: 0;
						line-height: 2;
						margin: 0;
						border-bottom: 1px solid #ececec;
						color: #1b3255;
						&:hover {
							background-color: $theme-color;
							color: $white;
							border-color: transparent;
						}
					}
					& .sub-menu {
						left: 100%;
						top: 50%;
					}
					&:hover {
						& .sub-menu {
							top: 100%;
						}
					}
				}
			}
			&:hover {
				&.menu-item-has-children{
					& > a{
						&:after{
							color: $theme-color;
						}
					}
				}
				& > a{
					color: $theme-color;
				}
				& > .sub-menu {
					opacity: 1;
					visibility: visible;
					top: 100%;
				}
			}
			& .dd-trigger {
				display: none;
			}
		}
	}
	& .site-branding{
		position: relative;
	}
	& .nav-menu{
		float: right;
	}
	& .nav-right{
		width: 100%;
		text-align: right;
		& ul{
			display: -webkit-box;
			& li{
				display: inline-block;
				margin-left: 45px;
				&:first-child{
					margin-left: 0px;
				}
				@media #{$lp}{
					margin-left: 17px;
				}
				@media #{$lg}{
					margin-left: 17px;
				}
				@media #{$xm}{
					margin-left: 0px;
				}
				& a{
					color: $heading-color;
					position: relative;
					& span.count{
						font-size: 10px;
						padding: 5px;
						background-color: $theme-color;
						border-radius: 50%;
						line-height: 6px;
						color: $white;
						position: absolute;
						top: -6px;
						right: -9px;
					}
					&.search-btn{
						@media #{$md}{
							margin-right: 20px;
						}
						@media #{$xs}{
							display: none;
						}
					}
				}
				& .main-btn{
					border-radius: 0px;
					font-weight: 500;
					color: $white;
					@media #{$lp}{
						padding: 14px 25px;
						font-size: 14px;
					}
					@media #{$lg}{
						padding: 14px 15px;
						font-size: 14px;
					}
					@media #{$xm}{
						display: none;
					}
				}
			}
		}
	}
	&.breakpoint-on {
		& .nav-menu{
			background-color: #fff;
			position: fixed;
			top: 0;
			left: -300px;
			z-index: 9999;
			width: 300px;
			height: 100%;
			transition-duration: 500ms;
			padding: 0;
			box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
			display: block;
			overflow-x: hidden;
			overflow-y: scroll;
			padding-top: 70px;

			&.menu-on {
				left: 0;
			}

			& .site-branding{
				@media #{$lg}{
					display: block !important;
				}
				@media #{$xm}{
					display: block !important;
				}
			}

			& .nav-search{
				margin: 0 20px;
				margin-bottom: 30px;
				@media #{$xs}{
					display: block !important;
				}
				& .form_group{
					& .form_control{
						border: 1px solid #e1e1e1;
					}
				}
			}
			& .main-menu {
				& ul li {
					display: block;
					margin: 0;
					border-bottom: 1px solid #ececec;
					&:last-child{
						border-bottom: 0;
					}
					&.active{
						& .sub-menu{
							border-top: 1px solid #ececec;
						}
					}
					& a {
						display: block;
						border-bottom: 1px solid rgba(255, 255, 255, 0.5);
						color: $heading-color;
						padding: 13px 20px;
					}
					& .sub-menu {
						width: 100%;
						position: relative;
						top: 0;
						left: 0;
						box-shadow: none;
						background-color: transparent;
						visibility: visible;
						opacity: 1;
						display: none;
						transition: none;
						& li a {
							color: $heading-color;
							padding: 0px 20px 0 40px;
							line-height: 45px;
							&:hover {
								border-color: rgba(255, 255, 255, 0.5);
							}
						}
					}
					& .dd-trigger {
						display: block;
						position: absolute;
						right: 0;
						height: 45px;
						width: 45px;
						top: 0;
						border-left: 1px solid rgba(255, 255, 255, 0.5);
						z-index: 2;
						background: transparent;
						text-align: center;
						line-height: 45px;
						cursor: pointer;
						color: $heading-color;
						font-size: 20px;
					}
				}
				&.menu-on {
					left: 0;
				}
			}
		}
		.navbar-close,
		.navbar-toggler {
			display: block;
		}
	}
	& .navbar-toggler {
		padding: 0;
		border: none;
		background-color: transparent;
		cursor: pointer;
		display: none;
		& span {
			position: relative;
			background-color: $heading-color;
			border-radius: 3px;
			display: block;
			height: 3px;
			margin-top: 5px;
			padding: 0;
			transition-duration: 300ms;
			width: 30px;
			cursor: pointer;
			display: block;
		}
		&.active {
			span:nth-of-type(1) {
				transform: rotate3d(0, 0, 1, 45deg);
				top: 8px;
			}
			span:nth-of-type(2) {
				opacity: 0;
			}
			span:nth-of-type(3) {
				transform: rotate3d(0, 0, 1, -45deg);
				top: -8px;
			}
		}
	}
	& .navbar-close {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 12;
		display: none;
		background: $theme-color;
    	padding: 5px;
		& .cross-wrap {
			width: 34px;
			height: 34px;
			cursor: pointer;
			position: relative;
			& span {
				position: absolute;
				display: block;
				width: 100%;
				height: 2px;
				border-radius: 6px;
				background: $white;
				&.top {
					top: 17px;
					left: 0;
					-webkit-transform: rotate(45deg);
					transform: rotate(45deg);
				}
				&.bottom {
					bottom: 15px;
					left: 0;
					-webkit-transform: rotate(-45deg);
					transform: rotate(-45deg);
				}
			}
		}
	}
	& .container-fluid{
		padding-left: 65px;
		padding-right: 65px;
		@media #{$lg}{
			padding-left: 15px;
			padding-right: 15px;
		}
		@media #{$xm}{
			padding-left: 15px;
			padding-right: 15px;
		}
	}
}
.header-top{
	background-color: $heading-color;
	padding: 11px 0px;
	font-size: 13px;

	& .container-fluid{
		padding-left: 65px;
		padding-right: 65px;
		@media #{$lg}{
            padding-left: 15px;
            padding-right: 15px;
        }
		@media #{$xm}{
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	& .top-left{
		@media #{$xm}{
			display: none;
		}
		& ul{
			& li{
				display: inline-block;
				margin-right: 40px;
				@media #{$lg}{
					margin-right: 20px;
				}
				& span{
					color: $white;
					& i{
						margin-right: 10px;
					}
				}
			}
		}
	}
	& .top-right{
		float: right;
		@media #{$xm}{
			float: none;
			text-align: center;
		}
		& ul.social-link{
			& li{
				display: inline-block;
				margin-left: 25px;
				@media #{$xm}{
					margin-left: 10px;
					margin-right: 10px;
				}
				& a{
					color: $white;
				}
			}
		}
	}
}
.header-area-v2{
    & .main-menu{
		& ul{
			& > li{
				&.menu-item-has-children{
					& > a{
						&:after{
							color: $white;
						}
					}
				}
			}
		}
		& ul li {
			display: inline-block;
			position: relative;
			& > a {
				color: $white;
			}
		}
	}
	& .navbar-toggler{
		& span{
			background-color: $white;
		}
	}
	& .nav-right{
		& ul{
			& li{
				& a{
					color: $white;
				}
			}
		}
	}
}
// stcky animation
.header-navigation{
	&.sticky{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 999;
		animation: sticky  1.2s;
		background-color: $white;
		box-shadow: 0px 12px 30px 0px rgb(174 184 198 / 20%);
	}
}
.header-area-v2{
	& .header-navigation{
		&.sticky{
			background-color: $heading-color;
		}
	}
}
@-webkit-keyframes sticky {
    0% {
        top: -200px;
    }

    100% {
        top: 0;
    }
}
@keyframes sticky {
    0% {
        top: -200px;
    }

    100% {
        top: 0;
    }
}
