@import "@/assets/scss/style";
.sponsor-area-v1 {
	& .sponsor-slider-one {
		padding-left: -15px;
    padding-right: -15px;

		& .single-sponsor {
			padding-left: 15px;
			padding-right: 15px;
			display: flex;
			justify-content: center;
		}
	}
}
