

/*===========================
    1. COMMON css
===========================*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Roboto:wght@400;500;700&display=swap');

html{
    font-size: 100%;
}
*{
    margin: 0;
    padding: 0;
    @include box-sizing (border-box);
}
a{
    color: inherit;
    text-decoration: none;
    @include transition(.3s);
    &:hover,&:focus{
        color: inherit;
        text-decoration: none;
    }
}
a:focus,
input:focus,
textarea:focus,
button:focus {
    text-decoration: none;
    outline: none;
}

i,
span,
a{
    display: inline-block;
}
h1,
h2,
h3{
    font-weight: 700;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $poppins;
    color: $heading-color;
    margin: 0px;
}
h1 {
    font-size: 45px;
    line-height: 65px;
}
h2 {
    font-size: 44px;
    line-height: 54px;
}
h3 {
    font-size: 30px;
}
h4 {
    font-size: 22px;
    font-weight: 600;
    line-height: 32px;
}
h5 {
    font-size: 18px;
}
h6 {
    font-size: 16px;
}
ul,ol {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}
p{
    color: $text-color;
    margin: 0px;
}
input,textarea{
    display: inherit;
}
img{
    max-width: 100%;
}
body {
    font-family: $roboto;
    font-weight: normal;
    font-style: normal;
    color: $text-color;
    font-size: 16px;
    line-height: 28px;
    overflow-x: hidden;
}
.bg_cover{
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
.slick-slide {
    outline: 0;
}
.container {
    @media #{$xl} {
        max-width: 1200px;
    }

    &--large {
        max-width: 1290px;
    }
}
.custom-container{
    max-width: 1420px;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    margin-right: auto;
    margin-left: auto;
}
.form_group{
    position: relative;
        & i{
        font-size: 14px;
    }
}
.form_control{
    width: 100%;
    padding: 0 30px;
    height: 70px;
    border: none;
}
// others common css here

.section-title{
    & span{
        color: $theme-color;
        margin-bottom: 10px;
        font-weight:500;
        &:before,&:after{
            display: inline-block;
            content: '';
            width: 30px;
            height: 2px;
            background-color: $theme-color;
            vertical-align: middle;
        }
        &:before{
            margin-right: 20px;
            @media #{$xss}{
                margin-right: 10px;
            }
        }
        &:after{
            margin-left: 20px;
            @media #{$xss}{
                margin-left: 10px;
            }
        }
    }
    & h2{
        margin-bottom: 15px;
        font-size: 44px;
        line-height: 56px;
        @media #{$lg}{
            font-size: 32px;
            line-height: 42px;
        }
        @media #{$xs}{
            font-size: 26px;
            line-height: 36px;
        }
    }
    &.section-title-left{
        & span{
            &:after{
                display: none;
            }
        }
    }
}
.section-white-title{
    & h2{
        color: $white;;
    }
}
.light-bg{
    background: $light-bg;
}
.main-bg{
    background-color: $theme-color;
}
/*===== All Button Style =====*/
.main-btn{
    display: inline-block;
    padding: 14px 45px;
    font-size: 16px;
    font-family: $roboto;
    color: $white;
    background: $theme-color;
    border-radius: 28px;
    @include transition(.3s);
    &:hover,&:focus{
        background: $heading-color;
        color: $white;
    }
}
button{
    border: none;
}

p {
    font-size: 18px;

    a {
        color: $theme-color;

        &:hover {
            color: $theme-color;
            text-decoration: underline;
        }
    }
}
/*
    Start Preloader css
*/
.preloader {
    background-color: $white;
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 9999;
}
.lds-ellipsis {
    margin: 0 auto;
    position: relative;
    top: 50%;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 64px;
    text-align: center;
    z-index: 9999;
	display: flex;
	justify-content: space-around;
}
.lds-ellipsis span {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: $theme-color;
    -webkit-animation: ball-pulse-sync .6s 0s infinite ease-in-out;
    animation: ball-pulse-sync .6s 0s infinite ease-in-out;
}
.lds-ellipsis span:nth-child(1) {
    -webkit-animation: ball-pulse-sync .6s -.14s infinite ease-in-out;
    animation: ball-pulse-sync 0.6s -0.14s infinite ease-in-out; }

.lds-ellipsis span:nth-child(2) {
    -webkit-animation: ball-pulse-sync .6s -70ms infinite ease-in-out;
    animation: ball-pulse-sync 0.6s -70ms infinite ease-in-out; }

@-webkit-keyframes ball-pulse-sync {
  33% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px); }
  66% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }
@keyframes ball-pulse-sync {
  33% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px); }
  66% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }
/*
    End Preloader css
*/
@keyframes pulse-border {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1; }
  100% {
    -webkit-transform: scale(1.8);
    -moz-transform: scale(1.8);
    -ms-transform: scale(1.8);
    -o-transform: scale(1.8);
    transform: scale(1.8);
    opacity: 0; } }

.p-toast-top-right {
    top: 113px;
}
